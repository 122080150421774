const compareText = function(str1='',str2=''){
    let map1 = {};
    let count = 0;
    // 
    for(let i = 0;i < str1.length;i++)
    {
        let key = str1[i];
        if(!map1.hasOwnProperty(key)) map1[key] = 0;
        map1[key] ++;
    }

    for(let i = 0;i < str2.length;i++)
    {
        let key = str2[i];
        if(map1.hasOwnProperty(key))
        {
            count += map1[key];
            map1[key] = 0;
        }
    }
    return count / str1.length;
}

export default compareText