<template>
    <div class="container">
        <div class="back">
            <div class="goback">
                <el-page-header style="margin-bottom: 10px;" @back="goBack">
                    <div slot="title" style="color:rgb(51, 102, 102);">
                        待评审列表
                    </div>
                    <div slot="content">
                        <!-- <span style="color:red">
                            论文原价加载中请等待
                        </span> -->
                    </div>
                </el-page-header>
               
            </div>
        </div>
        <div class="box" ref="box">
            <div class="left">
                <!-- <div class="iframeDiv">
                    <iframe scrolling="no" style="width:calc(100% - 6px);height:calc(100% - 8px)"
                        :src="`/static/pdf/web/viewer.html?file=${src}`"></iframe>
                </div> -->
                <div class="iframeDiv paperRaw" id="paperRaw">
                </div>
            </div>
            <div class="resize" title="收缩侧边栏">
                ⋮
            </div>
            <transition name='fade-transform' mode="out-in">
                <div class="mid receiptTable" v-if="reviewDisplay">
                    <el-descriptions class="tdOverflow" border 
                    :labelStyle="{'width':'30%','background':'#fff',textAlign:'center',color:'#606266',fontWeight:'bold'}"  
                    :contentStyle="{'width':'70%'}" :column="1">
                        <el-descriptions-item  label="题目">
                            <!-- :style="{'overflow':'hidden','white-space':'nowrap','textOverflow':'ellipsis'}" -->
                            <div :title="paperInfo.name" :style="{'overflow':'hidden','white-space':'nowrap','textOverflow':'ellipsis'}">{{paperInfo.name}}</div>
                        </el-descriptions-item>
                        <el-descriptions-item label="类型">{{paperInfo.type}}</el-descriptions-item>
                    </el-descriptions>
                    <vxe-table border align="center" id="review" ref="dataTable" :cell-style='cellStyleNumber' v-show="reviewDisplay"
                        :cell-class-name="cellClassName" style="width: 100%;" :span-method="mergeMethod" :data="dataTable"
                        element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
                        <vxe-column field="firstIndex" title="指标" width="30%">
                            <template #default="{row,rowIndex}">
                                <template v-if="row.type == '4'">
                                    <!-- 总分 -->
                                    <div v-html="row.firstIndex + '（满分 ' + row.score + ' 分）'"></div>
                                    <!-- {{row.firstIndex}}（{{row.score}} 分） -->
                                </template>
                                <template v-else-if="row.type == '6'">
                                    <!-- 注释 -->
                                    <div class="txtareaCom" v-html="row.comment">
                                        <!-- {{row.comment}} -->
                                    </div>
                                </template>
                                <template v-else-if="row.type == '5'">
                                    <!-- 文本型 -->
                                    <div class="txtareaCom">
                                        <span style="color:#000" v-html="row.firstIndex"></span>
                                        <span v-if="row.comment" v-html="'（'+row.comment + '）'"></span>
                                    </div>
                                    <el-input type="textarea" :class="{'txtarea':true,'failed':row.failed}" @input="textChangeHandle"
                                        :placeholder="'字数限制：'+row.unselectedMark+'-'+row.selectedMark" hidden
                                        :autosize='{ minRows: 15 }' v-model="row.content" :maxlength="row.selectedMark"
                                        :minlength="row.unselectedMark">
                                    </el-input>
                                    <span style="color: #909399;fontSize: 12px;position: absolute; right: 10px; bottom: 5px;">{{ wordCount(row.content) }}/{{row.selectedMark}}</span> 
                                </template>
                                <template v-else>
                                    <!-- 数值 -->
                                    <div v-html="row.firstIndex"></div>
                                    <!-- {{row.firstIndex}} -->
                                </template>
                            </template>
                        </vxe-column>
                        <vxe-column field="secondIndex" title="评价要素" header-align="center" align="left" width="50%">
                            <template #default="{row}">
                                <template v-if="row.type == '2'">
                                    <!-- 单选 -->
                                    <vxe-radio v-model="singleSelects[row.group]" class="tableRadio" :label="row.score"
                                        :content="content(row.score,Number(row.leftRange),Number(row.rightRange))"
                                        :disabled="row.rightRange !='' && row.rightRange && !(Number(row.leftRange)<=totalTable[0].content && totalTable[0].content<=Number(row.rightRange))">
                                    </vxe-radio>
                                    <div v-if="row.comment" v-html="row.comment">
                                        <!-- {{row.comment}} -->
                                    </div>
                                </template>
                                <template v-else-if="row.type == '3'">
                                    <!-- 多选 -->
                                    <vxe-checkbox v-model="row.content" class="tableCheckbox" :content="row.score">
                                    </vxe-checkbox>
                                    <div v-if="row.comment" v-html="row.comment">
                                        <!-- {{row.comment}} -->
                                    </div>
                                </template>
                                <template v-else>
                                    <!-- 数值 -->
                                    <div v-html="row.secondIndex + '（满分 ' + row.score + ' 分）'"></div>
                                    <!-- {{row.secondIndex}} （{{row.score}} 分） -->
                                </template>
                            </template>
                        </vxe-column>
                        <vxe-column field="content" title="评价" width="20%">
                            <template #default="{row}">
                                <!-- 数值 -->
                                <template v-if="row.type=='4'">
                                    {{row.content?row.content:0}} 分
                                </template>
                                <template v-else>
                                    <vxe-input v-model="row.content" type="number" min="0" :max="row.score"
                                        @change='contentChange(row.content,row.score)' clearable>
                                    </vxe-input>
                                </template>
                            </template>
                        </vxe-column>
                    </vxe-table>
                    <div class="signTable" v-show="signature == 1">
                        <vxe-table align="center" :cell-style='signCellStyle' :show-header="false" border :data="signTable">
                            <vxe-column field="label" width="30%"></vxe-column>
                            <vxe-column field="url" width='50%'>
                                <template #default='{row}'>
                                    <div>
                                        <el-image style="width:80px;height:40px;" v-show="sign.img!=''" :src="sign.img">
                                        </el-image>
                                    </div>
                                </template>
                            </vxe-column>
                            <vxe-column field="handWriting" width="20%">
                                <template #default='{row}'>
                                    <div>
                                        <el-button type="success" @click="signDiaVis = true" size="small">手写板签名
                                        </el-button><br>
                                        <!-- <el-button type="success" style="margin-top:5px;" @click="createQrcode"
                                            size="small">微信扫码签名
                                        </el-button> -->
                                    </div>
                                </template>
                            </vxe-column>
                        </vxe-table>
                    </div>
                    <div class="btn">
                        <el-button type="primary" @click="HandleSaveReview" size="medium">保存评审结果</el-button>
                        <el-button type="primary" @click="HandlePostReview" size="medium">提交评审结果</el-button>
                    </div>
                </div>
                <el-empty class="mid receiptTable" description="论文原件加载中,请您耐心等待" v-else="reviewDisplay"></el-empty>
            </transition>
            

            <el-dialog class="qrcode" :visible.sync="qrcodeModal" width="252px" :close-on-click-modal='false'>
                <template #title>
                    请使用微信扫描下方二维码
                </template>
                <img :src="qrcode" width="192">
            </el-dialog>

            <el-dialog title="电子签名" :visible.sync="signDiaVis" width="450px">
                <div>
                    <vue-esign style="border:1px solid #999;" ref='esign' :width='sign.width' :height='sign.height'
                        :isCrop='sign.isCrop' :lineWidth='sign.lineWidth' :lineColor='sign.lineColor'
                        :bgColor.sync='sign.bgColor'></vue-esign>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="medium" @click="resetHandle">清空</el-button>
                    <el-button type="primary" size="medium" @click="generateHandle">生成电子签名</el-button>
                </span>
            </el-dialog>

        </div>

        <change-info :expertId='expertId'></change-info>
    </div>
</template>

<script>
    import changeInfo from '@/components/personalInfo/changeInfo'
    import pdf from 'pdfobject'
    import compareText from '@/tools/compareText'
    import Similarity from '@/tools/Similarity'
    import QRCode from 'qrcode'
    import {
        compressImgBySize,
        imgToCanvas
    } from '@/tools/compressImg'
    import { mapState } from 'vuex'
    export default {
        name: 'ReviewPage',
        data() {
            return {
                // encodeURIComponent('/static/1.pdf')
                src: '',
                numberTable: [],
                totalTable: [],
                singleTable: [],
                singleSelects: {},
                mulTable: [],
                textTable: [],
                commentTable: [],
                paperId: '',
                reviewId: '',
                expertId: 0,
                paperLoading: null,
                reviewDisplay:false,
                scoreIndex: {},
                dataTable: [],
                singleSelectText: {},
                // 电子签名
                signature: 1,
                signTable: [{
                    label: '专家电子签名',
                    url: ''
                }],
                signDiaVis: false,
                sign: {
                    width: 400,
                    height: 200,
                    lineWidth: 4,
                    lineColor: '#000000',
                    bgColor: '',
                    img: '',
                    isCrop: true
                },
                replaceMap: null,
                qrcode: '',
                qrcodeModal: false,
                textIndex:0,
                textColor:[false,false]
            }
        },
        computed:{
            ...mapState({paperInfo:state=>state.reviewPaper.paperInfo})
        },
        mounted() {
            compareText();
            this.changeIframeDivStyle('');
            this.dragControllerDiv();
        },
        created() {
            // /static/1.pdf
            // http://121.199.73.238:8888/paper/244/1.pdf
            // this.src = "http://127.0.0.1:8088/file/234.pdf";
            this.paperId = this.$route.params.paperId;
            this.reviewId = this.$route.params.reviewId;
            // this.src = encodeURI('http://192.168.0.111:8888/expert/download_file?path=paper/52/19085204881.pdf')
            this.getPaper();
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                this.paperLoading = this.$loading({
                    lock: true,
                    text: '论文原件加载中,请您耐心等待',
                    spinner: 'el-icon-loading',
                    target: document.getElementsByClassName('left')[0]
                    // background: 'rgba(0, 0, 0, 0.7)'
                });
            });

            this.getReplaceMap();
        },
        methods: {
            async getReplaceMap() {
                await this.$api.reviewPage.getReplaceMap()
                    .then(res => {
                        this.replaceMap = res.data.data;
                    })
            },
            getPaperRaw(params) {
                this.$api.reviewPage.getPaperRaw(params).then(res => {
                    let data = res.data;
                    const blob = new Blob([data], {
                        type: 'application/pdf;charset=utf-8'
                    });
                    let url = window.URL || window.webkitURL;
                    // 将blob对象创建新的url
                    const blobUrl = url.createObjectURL(blob);
                    // 将url传送到PDF.js
                    this.src = blobUrl;
                    pdf.embed(this.src, "#paperRaw");
                    this.paperLoading.close();
                })
            },
            // 获取电子签名
            getSignature(reviewId) {
                let params = {
                    reviewId: reviewId
                }
                this.$api.reviewPage.getSignature(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.sign.img = res.data.data;
                        }
                    })
            },
            // 获取论文原件评审信息
            getPaper() {
                let params = {
                    paperId: this.paperId,
                    reviewId: this.reviewId
                };
                this.$api.reviewPage.getPaper(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.signature = res.data.data.signature;
                            if (this.signature == 1) {
                                this.getSignature(this.reviewId);
                            }
                            let tem = res.data.data.template;
                            let url = res.data.data.paperUrl;
                            this.getPaperRaw({
                                path: url
                            });
                            this.numberTable = tem['0'] ? tem['0'] : [];
                            this.singleTable = tem['2'] ? tem['2'] : [];
                            this.mulTable = tem['3'] ? tem['3'] : [];
                            this.totalTable = tem['4'] ? tem['4'] : [];
                            let textTable = tem['5'] ? tem['5'] : [];
                            this.commentTable = tem['6'] ? tem['6'] : [];
                            this.changeSingle();
                            this.changeMul();
                            this.changeText(textTable);
                            this.changeDataTable();
                            this.reviewDisplay = true;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 合并数值单选特征
            changeDataTable() {
                let arr = [];
                this.numberTable.forEach(item => {
                    arr.push(item);
                });
                if (this.numberTable.length > 1) {
                    this.totalTable.forEach(item => {
                        arr.push(item);
                    });
                }
                this.singleTable.forEach(item => {
                    arr.push(item);
                });
                this.mulTable.forEach(item => {
                    arr.push(item);
                });
                this.textTable.forEach(item => {
                    arr.push(item);
                });
                this.commentTable.forEach(item => {
                    arr.push(item);
                })
                let n = arr.length;
                for (let i = 0; i < n; i++) {
                    for (let j = i + 1; j < n; j++) {
                        let irank = arr[i].innerRanking;
                        let jrank = arr[j].innerRanking;
                        if (arr[i].type == '2' || arr[j].type == '3') irank /= 100;
                        if (arr[j].type == '2' || arr[j].type == '3') jrank /= 100;
                        if (irank > jrank) {
                            let t = arr[i];
                            arr[i] = arr[j];
                            arr[j] = t;
                        }
                    }
                }
                this.dataTable = arr;
            },
            content(score, leftRange, rightRange) {
                let content = score;
                if (Number(leftRange) == 0 && Number(rightRange) == 100) return content;
                if (!rightRange) return content;
                content += '（' + leftRange + '-' + rightRange + ' 分' + '）';
                return content;
            },
            changeText(textTable) {
                String.prototype.replaceAll = function (s1, s2) {
                    return this.replace(new RegExp(s1, "gm"), s2);
                };
                for (let i = 0; i < textTable.length; i++) {
                    textTable[i].failed = false;
                    textTable[i].content = textTable[i].content.replaceAll(
                        '<w:br/>&#10;&#10;&#10;&#10;', '\n    ');
                    for (let j = this.replaceMap.length - 1; j >= 0; j--) {
                        let item = this.replaceMap[j];
                        textTable[i].content = textTable[i].content.replaceAll(item.value, item.key)
                    }
                    let t = textTable[i].content.trim();
                    if (t) textTable[i].content = '    ' + t;
                }
                this.textTable = textTable;
            },
            contentChange(
                value, maxn
            ) {
                if (this.totalScore < 0) return;
                if (Number(value) < 0) return;
                if (Number(value) > Number(maxn)) return;
                let score = 0;
                this.numberTable.forEach(item => {
                    score += Number(item.content) * Number(item.leftRange);
                });
                score = parseFloat(score.toFixed(2));
                this.totalTable[0].content = score;
                for (let key in this.singleSelects) {
                    if (this.singleSelects[key]) {
                        let index = this.scoreIndex[key][this.singleSelects[key]];
                        let l = Number(this.singleTable[index].leftRange);
                        let r = Number(this.singleTable[index].rightRange);
                        console.log(key,this.singleTable[index],l,r)
                        if (!(l == 0 && r == 0) && !(score >= l && score <= r)) this.singleSelects[key] = '';
                    }
                }
            },
            //保存评审结果
            HandleSaveReview() {
                this.save();
            },
            validate() {
                let flag = true;
                for (let i in this.numberTable) {
                    let item = this.numberTable[i];
                    if (item.content == '') {
                        this.$message.warning('“' + item.secondIndex + '” 信息未填写');
                        return false;
                    }
                }

                for (let key in this.singleSelects) {
                    if (this.singleSelects[key] == '') {
                        this.$message.warning('“' + this.singleSelectText[key] + '” 信息未选择,请点击选项');
                        return false;
                    }
                }

                let mulSelect = {};
                for (let i in this.mulTable) {
                    let key = this.mulTable[i].firstIndex;
                    if (!mulSelect.hasOwnProperty(key)) {
                        mulSelect[key] = this.mulTable[i].content;
                    } else {
                        if (this.mulTable[i].content == true) {
                            mulSelect[key] = true;
                        }
                    }
                };

                for (let key in mulSelect) {
                    if (mulSelect[key] == '') {
                        this.$message.warning('“' + key + '” 信息未选择,请点击选择');
                        return false;
                    }
                }


                for (let i = 0; i < this.textTable.length; i++) {
                    let item = this.textTable[i];
                    if (item.content.length < item.unselectedMark) {
                        this.$message.warning("审稿意见至少" + item.unselectedMark + '字');
                        return false;
                    } else if (item.content.length > item.selectedMark) {
                        this.$message.warning("审稿意见最多" + item.selectedMark + '字');
                        return false;
                    }
                }
                if(this.textTable.length >= 2){
                    let [cnt,scnt,tcnt] = Similarity(this.textTable[0].content,this.textTable[1].content);
                    let sim1 = cnt / scnt,sim2 = cnt / tcnt
                    let sim = Math.min(sim1,sim2);
                    // let maxn = Math.max(sim1,sim2);
                    // let minx = Math.min(sim1,sim2);
                    // console.log("相似度",minx,maxn);
                    // if(maxn > 0.95 && minx > 0.55){
                    //     this.textTable[0].failed = true;
                    //     this.textTable[1].failed = true;
                    //     this.$message.warning("文字重复率过高，请修改后提交。");
                    //     return false;
                    // }
                    if(sim > 0.8){
                        this.textTable[0].failed = true;
                        this.textTable[1].failed = true;
                        this.$message.warning("文字重复率过高，请修改后提交。");
                        return false;
                    }
                }

                if (this.signature == 1 && this.sign.img == '') {
                    this.$message.warning("请输入电子签名！");
                    return false;
                }

                // for (let i = 0; i < this.textTable.length; i++) {
                //     for (let j = 0; j < this.textTable.length; j++) {
                //         if (compareText(this.textTable[i].content, this.textTable[j].content) > 0.8) {
                //             this.$message.warning(this.textTable[i].firstIndex + '文本在' + this.textTable[j].firstIndex +
                //                 '文本中的重复率超过80%，请修改');
                //             return falsel
                //         }
                //         if (compareText(this.textTable[j].content, this.textTable[i].content) > 0.8) {
                //             this.$message.warning(this.textTable[j].firstIndex + '文本在' + this.textTable[i].firstIndex +
                //                 '文本中的重复率超过80%，请修改');
                //             return false;
                //         }
                //     }
                // }

                return flag;
            },
            checkInfo() {
                this.$api.info.getCheckInfo().then(res => {
                        if (res.data.code == 1) {
                            this.$store.dispatch('professor/changeIsChangeDiaVis', true);
                        } else {
                            this.$store.dispatch('professor/changeIsChangeDiaVis', false);
                            this.submit();
                        }
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            //提交评审结果
            HandlePostReview() {
                if (!this.validate()) {
                    return;
                }
                this.checkInfo();
            },
            // 保存
            save() {
                const loading = this.$loading({
                    lock: true,
                    text: '正在保存',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let reviewDetails = [];
                this.numberTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: item.content,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                this.singleTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: this.singleSelects[item.group] == item.score ? 1 : 0,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                this.mulTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: item.content == true ? 1 : 0,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                this.totalTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: item.content,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                String.prototype.replaceAll = function (s1, s2) {
                    return this.replace(new RegExp(s1, "gm"), s2);
                };
                this.textTable.forEach((item, index) => {
                    let pars = item.content.split('\n');
                    let arr = [];
                    pars.forEach((item, index) => {
                        let text = item.trim();
                        text = text.replaceAll('\t', '');
                        text = text.replaceAll('', '');
                        this.replaceMap.forEach(item => {
                            text = text.replaceAll(item.key, item.value)
                        })
                        let judge = text.replaceAll('\n', '');
                        if (judge) arr.push(text);
                    });
                    let content = arr.join("<w:br/>&#10;&#10;&#10;&#10;");
                    // let content = pars.join("&#xA;&#10;&#10;&#10;&#10;");
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: content,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                let params = {
                    isSubmit: 0,
                    reviewId: this.reviewId,
                    reviewDetailsVo: reviewDetails
                };

                this.$api.reviewPage.postSaveReviewContent(params)
                    .then(res => {
                        loading.close();
                        if (res.data.code == 200) {
                            this.$message.success("保存成功");
                        } else {
                            this.$message.warning("保存失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 提交
            submit() {
                const loading = this.$loading({
                    lock: true,
                    text: '正在提交',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let reviewDetails = [];
                this.numberTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: item.content,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                this.singleTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: this.singleSelects[item.group] == item.score ? 1 : 0,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                this.mulTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: item.content == true ? 1 : 0,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                this.totalTable.forEach((item, index) => {
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: item.content,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                String.prototype.replaceAll = function (s1, s2) {
                    return this.replace(new RegExp(s1, "gm"), s2);
                };
                this.textTable.forEach((item, index) => {
                    let pars = item.content.split('\n');
                    let arr = [];
                    pars.forEach((item, index) => {
                        let text = item.trim();
                        text = text.replaceAll('\t', '');
                        text = text.replaceAll('', '');
                        this.replaceMap.forEach(item => {
                            text = text.replaceAll(item.key, item.value)
                        })
                        let judge = text.replaceAll('\n', '');
                        if (judge) arr.push(text);
                    });
                    let content = arr.join("<w:br/>&#10;&#10;&#10;&#10;");
                    // let content = pars.join("&#xA;&#10;&#10;&#10;&#10;");
                    let t = {
                        templateDetailId: item.templateDetailId,
                        content: content,
                        innerRanking: item.innerRanking
                    };
                    reviewDetails.push(t);
                });
                let params = {
                    isSubmit: 1,
                    reviewId: this.reviewId,
                    reviewDetailsVo: reviewDetails
                };

                this.$api.reviewPage.postSaveReviewContent(params)
                    .then(res => {
                        loading.close();
                        if (res.data.code == 200) {
                            this.$message.success("提交成功！");
                            this.$router.push({
                                name: 'unfinishedList'
                            });
                        } else {
                            this.$message.warning("提交失败！");
                        }
                    }).catch(err => {
                        loading.close();
                        this.$message.warning("服务器维护！");
                    })
            },
            changeSingle() {
                let groupSelected = {};
                console.log(this.singleTable) 
                this.singleTable.forEach((item, index) => {
                    // this.scoreIndex[item.score] = index;
                    if(!this.scoreIndex.hasOwnProperty(item.group)) this.scoreIndex[item.group] = {}
                    this.scoreIndex[item.group][item.score] = index
                    if (!groupSelected.hasOwnProperty(item.group) || groupSelected[item
                            .group] == '') {
                        groupSelected[item.group] = item.content == '1' ? item.score : '';
                    }
                    this.singleSelectText[item.group] = item.firstIndex;
                });
                console.log(groupSelected)
                this.singleSelects = groupSelected;
            },
            changeMul() {
                this.mulTable.forEach(item => {
                    item.content = item.content && item.content == '1' ? true : false;
                })
            },
            // 生成电子签名
            resetHandle() {
                this.$refs.esign.reset();
            },
            generateHandle() {
                this.$refs.esign.generate().then(resimg => {
                    this.sign.img = resimg;
                    let params = {
                        reviewId: this.reviewId,
                        expertSignatureBase64: resimg
                    }
                    this.$api.reviewPage.postSaveSignature(params)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.signDiaVis = false;
                                this.resetHandle();
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护！");
                        })
                }).catch(err => {
                    console.log(err);
                })
            },
            // 计算个数
            wordCount(word){
                if(!word) return 0;
                let count = 0;
                for(let i = 0;i < word.length;i ++){
                    if(word[i] != ' ' && word[i] !='\n' && word[i] !='\t') count ++;
                }
                return count;
            },
            textChangeHandle(){
                this.textTable.forEach(item=>{
                    item.failed = false;
                })
            },
            setQRCode(str) {
                QRCode.toDataURL(str, {
                    width: 192,
                    margin: 1
                }).then(res => {
                    // toDataURL方法返回一个promise
                    this.qrcode = res;
                })
            },
            createQrcode() {
                this.$api.user.getExpertToken()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.setQRCode(`http://192.168.2.8:8080/#/signature?token=${res.data.data}`);
                            console.log(`http://192.168.2.8:8080/#/signature?token=${res.data.data}`);
                            this.qrcodeModal = true;
                            this.mobileSaveSignatureStateCheck(res.data.data);
                        }
                    })

            },
            mobileSaveSignatureStateCheck(token){
                let param = new URLSearchParams();
                param.append('token',token)
                this.$api.reviewPage.mobileSaveSignatureStateCheck(param)
                .then(res=>{
                    if(res.data.data == false) {
                        let timer = setTimeout(()=>{
                            this.mobileSaveSignatureStateCheck(token);
                            timer = null;
                        },1000)
                    }else{
                        this.getSignature(this.reviewId);
                        this.qrcodeModal = false;
                    }
                })
            },
            //返回上一页
            goBack() {
                this.$router.push({
                    name: 'unfinishedList'
                });
            },
            changeIframeDivStyle(display) {
                var iframeDiv = document.getElementsByClassName('iframeDiv');
                iframeDiv[0].style.display = display;
            },
            dragControllerDiv: function () {
                var resize = document.getElementsByClassName('resize');
                var left = document.getElementsByClassName('left');
                var mid = document.getElementsByClassName('mid');
                var box = document.getElementsByClassName('box');
                for (let i = 0; i < resize.length; i++) {
                    // 鼠标按下事件
                    resize[i].onmousedown = (e) => {
                        this.changeIframeDivStyle('none');
                        // 颜色改变提醒
                        resize[i].style.background = '#818181';
                        var startX = e.clientX;
                        resize[i].left = resize[i].offsetLeft;
                        // 鼠标拖动事件
                        document.onmousemove = (e) => {
                            var endX = e.clientX;
                            var moveLen = resize[i].left + (endX -
                                startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
                            var maxT = box[i].clientWidth - resize[i]
                                .offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

                            if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
                            if (moveLen > maxT - 150) moveLen = maxT - 150; //右边区域最小宽度为150px

                            resize[i].style.left = moveLen; // 设置左侧区域的宽度

                            for (let j = 0; j < left.length; j++) {
                                left[j].style.width = moveLen + 'px';
                                mid[j].style.width = (box[i].clientWidth - moveLen - 30) + 'px';
                            }
                        };
                        // 鼠标松开事件
                        document.onmouseup = (evt) => {
                            //颜色恢复
                            this.changeIframeDivStyle('');
                            resize[i].style.background = '#d6d6d6';
                            document.onmousemove = null;
                            document.onmouseup = null;
                            resize[i].releaseCapture && resize[i]
                                .releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
                        };
                        resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
                        return false;
                    };
                }
            },
            //合并表格
            mergeMethod({
                row,
                _rowIndex,
                column,
                _columnIndex,
                visibleData
            }) {
                let firstIndex = row['firstIndex'];
                let type = row['type'];
                // 总分型
                if (row.type == '4') {
                    let rows = 1,
                        cols = 1;
                    if (column.property == 'firstIndex') {
                        rows = 1;
                        cols = 2;
                    } else if (_columnIndex == 1) {
                        rows = 0;
                        cols = 0;
                    } else {
                        rows = 1;
                        cols = 1;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
                if (row.type == '5' || row.type == '6') {
                    let rows = 1,
                        cols = 1;
                    if (column.property == 'firstIndex') {
                        rows = 1;
                        cols = 3;
                    } else {
                        rows = 0;
                        cols = 0;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
                if (column.property == 'firstIndex') {
                    let prevRow = visibleData[_rowIndex - 1];
                    let nextRow = visibleData[_rowIndex + 1];
                    if (prevRow && prevRow['firstIndex'] == firstIndex) {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    } else {
                        // 一级指标相同合并 从第一行合并到第4行
                        let countRowspan = 1;
                        let countColspan = 1;
                        while (nextRow && nextRow['firstIndex'] == firstIndex && nextRow['type'] == type) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                        }
                        // if (row.type == 5 && _columnIndex == 1) {
                        //     countColspan = 2;
                        // }
                        if (countRowspan >= 1) {
                            return {
                                rowspan: countRowspan,
                                colspan: countColspan
                            };
                        }
                    }
                }
                // 单选型
                if (row.type == '2' || row.type == '3') {
                    let rows = 1,
                        cols = 1;
                    if (_columnIndex == 1) {
                        rows = 1;
                        cols = 2;
                    } else {
                        rows = 0;
                        cols = 0;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
            },
            // 表格样式
            cellStyleNumber({
                row,
                rowIndex,
                column,
                columnIndex
            }, tableData) {
                //设置状态颜色
                let styles = {};
                if (row.type == '5' || row.type == '6') {
                    styles['padding'] = '0px';
                }
                if ((row.type == '2' || row.type == '3') && columnIndex == 1) {
                    styles['text-align'] = 'left';
                }
                if (columnIndex == 0) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                }
                if (rowIndex == this.dataTable.length - 1) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                    return styles;
                }
                if (this.dataTable[rowIndex].type != this.dataTable[rowIndex + 1].type || this.dataTable[rowIndex]
                    .firstIndex != this.dataTable[rowIndex + 1].firstIndex) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                }
                return styles;
            },
            cellClassName({
                row,
                column
            }) {
                if (row.type == '5' || row.type == '6') {
                    return 'mycell';
                }
            },
            signCellStyle({
                row,
                rowIndex,
                column,
                columnIndex
            }) {
                let styles = {};
                styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                return styles;
            }
        },
        components: {
            'change-info': changeInfo,
        }
    }
</script>

<style scoped>
    .signTable .vxe-table--border-line {
        border: 1px solid rgb(153, 153, 153);
        border-top: 0px;
    }

    .signTable .vxe-table--body {
        width: 100% !important;
    }

    .paperRaw {
        height: 95vh;
        overflow: hidden;
    }

    .paperRaw::-webkit-scrollbar {
        display: none;
    }

    .container {
        /* padding: 10px; */
    }

    .back {
        height: 30px;
    }

    .goback {
        position: fixed;
        padding-left: 5px;
        padding-top: 5px;
        width: 100%;
        background-color: #fff;
        z-index: 100;
    }

    /*包围div样式*/
    .box {
        width: 100%;
        height: calc(100vh - 40px);
        margin-top: 10px;
        overflow: hidden;
        box-shadow: -1px 9px 10px 3px rgba(0, 0, 0, 0.11);
    }

    /*左侧div样式*/
    .left {
        width: calc(60% - 30px);
        /*左侧初始化宽度*/
        height: 100%;
        background: #FFFFFF;
        float: left;
        overflow: auto;
    }

    .iframeDiv {
        width: 100%;
        height: 100%;
        /* display: none; */
        /* position: absolute;
        z-index: 1111;
        filter: alpha(opacity=0);
        opacity: 0;
        background: transparent; */
        /* margin-top: 30px;  */
    }

    /*拖拽区div样式*/
    .resize {
        cursor: col-resize;
        float: left;
        position: relative;
        top: 45%;
        background-color: #d6d6d6;
        border-radius: 5px;
        /* margin-top: 40px; */
        width: 10px;
        height: 45px;
        background-size: cover;
        background-position: center;
        /*z-index: 99999;*/
        font-size: 32px;
        color: white;
        margin-right: 10px;
        margin-left: 10px;
        /* margin: 0px 10px; */
    }

    /*拖拽区鼠标悬停样式*/
    .resize:hover {
        color: #444444;
    }

    /*右侧div'样式*/
    .mid {
        float: left;
        width: 40%;
        /*右侧初始化宽度*/
        height: 100%;
        background: #fff;
        overflow: auto;
        /* box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11); */
    }

    .txtareaCom {
        border-bottom: 1px solid rgb(143, 143, 143);
        /* border-top: 1px solid rgb(143, 143, 143); */
        /* border-top: 0px; */
        padding: 10px;
        color: rgb(160, 160, 160);
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 14px;
    }

    .ptext {
        border-bottom: 1px solid rgb(143, 143, 143);
        padding: 10px 10px 10px 10px;
    }
    .txtarea{
        padding: 0 1px;
        box-sizing: border-box;
    }

    .txtarea .el-textarea__inner {
        /* border: 1px solid rgb(143, 143, 143); */
        border-top: 0px;
        border-radius: 0px;
    }

    .mid .btn {
        margin: 10px 10px;
        text-align: right;
    }

</style>

<style>
    .failed .el-textarea__inner{
        border: red solid 1px;
        border-radius: 0px;
    }
    .tableRadio .vxe-radio--label {
        display: inline;
    }

    .tableCheckbox .vxe-checkbox--label {
        display: inline;
    }

    .receiptTable .vxe-table--border-line {
        border: 1px solid rgb(153, 153, 153);
    }

    .mycell .vxe-cell {
        padding: 0px;
        text-align: left;
    }

    .qrcode .el-dialog__body {
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .tdOverflow .is-bordered{
        table-layout: fixed !important;
    }
</style>