function check(s){
    return s.charCodeAt(0) >= "a".charCodeAt(0) && s.charCodeAt(0) <= "z".charCodeAt(0)
    || s.charCodeAt(0) >= "A".charCodeAt(0) && s.charCodeAt(0) <= "Z".charCodeAt(0);
}
export default function Similarity(s,t){
    // 保存s中每个 ‘字’ 或 ‘单词’ 出现的个数
    let map = new Map(); 
    // scnt：保持s中 ‘字’ 或 ‘单词’ 的个数
    // tcnt：保持t中 ‘字’ 或 ‘单词’ 的个数
    let scnt = 0,tcnt = 0;
    for(let i = 0;i < s.length;i ++){
        let str = "";
        if(check(s[i])){
            let j = i;
            while(j < s.length && check(s[j])){
                str += s[j];
                j ++;
            }
            i = j - 1;
        }else{
            str = s[i];
        }
        if(str == ' ') continue;
        scnt ++;
        if(!map.has(str)) map.set(str,1);
        else map.set(str,map.get(str) + 1);
    }

    // 保存s中每个 ‘字’ 或 ‘单词’ 出现的个数
    let map1 = new Map();
    for(let i = 0;i < t.length;i ++){
        let str = "";
        if(check(t[i])){
            let j = i;
            while(j < t.length && check(t[j])){
                str += t[j];
                j ++;
            }
            i = j - 1;
        }else{
            str = t[i];
        }
        if(str == ' ') continue;
        tcnt ++;
        if(!map1.has(str)) map1.set(str,1);
        else map1.set(str,map1.get(str) + 1);
    }
    // cnt ： 同时在 s和t 中都出现的 ‘字’ 或 ‘单词’ 的数量。
    let cnt = 0;
    for(let key of map.keys()){
        if(map1.has(key)){
            cnt += Math.min(map.get(key),map1.get(key));
        }
    }
    // 返回 [重复出现的数量，s中字的数量，t中字的数量]
    return [cnt,scnt,tcnt];
}
